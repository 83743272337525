<template>
    <div class="fullMap d-flex align-items-center justify-content-center h-100">
        <!-- <img src="assets/images/locationMap.svg" alt="Map"> -->
       <div id="chartdiv" style="width: 100%; height: 500px"></div>
    </div>
</template>

<script>
    import { useMainStore } from "@/store";
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import * as am5map from "@amcharts/amcharts5/map";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
    import am5geodata_continentsLow from "@amcharts/amcharts5-geodata/continentsLow";
    import * as percent from "@amcharts/amcharts5/percent";
    import * as index from "@amcharts/amcharts5/index";
export default {
    setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {
      lastCountry : ''
    }
  },
  watch :{
    'store.selectedCountry'(){
      this.drawChart(this.store.selectedCountry)
    },

  },
  methods:{
    drawChart(country){
      let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'chartdiv');
      d?.dispose();
      var root = am5.Root.new("chartdiv");
      root.setThemes([am5themes_Animated.new(root)]);
      var chart = root.container.children.push(
        am5map.MapChart.new(root, {
          wheelY: "none",
          whhelX : "none",
          projection: am5map.geoMercator(),
        })
      );

      var polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_worldLow,
          exclude: ["AQ"],
        })
      );
      polygonSeries.useGeodata = true;
      polygonSeries.mapPolygons.template.setAll({
        tooltipText: "{name}",
        toggleKey: "active",
        interactive: true,
        templateField: "polygonSettings",
        fill: "#B7DCC9",
      });
      polygonSeries.mapPolygons.template.states.create("hover", {
        fill: "#B7DCC9",
      });

      polygonSeries.mapPolygons.template.states.create("active", {
        fill: "#16C784",
      });
      var previousPolygon;
      polygonSeries.mapPolygons.template.on("active", (active, target) => {
        this.lastCountry = target.dataItem.dataContext.name;
        if(this.$route.path == '/economic-overview'){
          this.store.$patch({selectedCountry : target.dataItem.dataContext.name})
          this.store.$patch({selectedIndicator : 'GDP'})
          this.$parent.showCountryData(target.dataItem.dataContext.name)
        }else{
          this.store.$patch({selectedCountry : target.dataItem.dataContext.name})
          //this.$parent.drawCompositionChart()
        }
        if (previousPolygon && previousPolygon != target) {
          previousPolygon.set("active", false);
        }
        if (target.get("active")) {
          polygonSeries.data.setAll([
            {
              id: target.dataItem.dataContext.id,
              polygonSettings: {
                active: true,
              },
            },
          ]);
          if(this.$route.path == '/markets'){
            this.$parent.getCountryWiseData()
          }else{
            this.callCountryEconomyData(this.store.selectedCountry)
          }
        } else {
          chart.goHome();
        }
        previousPolygon = target;
      });
      if (country && this.$route.path != '/economic-overview') {
        let countryObject = am5geodata_worldLow.features.find(
          (i) => i.properties.name === country
        );
        polygonSeries.data.setAll([
          {
            id: countryObject.id,
            polygonSettings: {
              active: true,
            },
          },
        ]);
         //this.callCountryEconomyData(this.store.selectedCountry)
      }
      chart.appear(1000, 100);
    },
    callCountryEconomyData(country){
      let formData = {};
      if(this.$route.path == '/gdp'){
        formData ={
            "country" : [country],
            "group" : this.store.selectedIndicator
        }
      }else if(this.$route.path == '/money-and-price'){
        formData ={
          "country" : country,
          "group" : this.store.selectedIndicator.split('&')
        }
      }else if(this.$route.path == '/markets'){
        formData ={
            "country" : [country],
            "group" : this.store.selectedIndicator
        }
      }else if(this.$route.path == '/business-consumer'){
        formData ={
          "country" : country,
          "group" : this.store.selectedIndicator.split('&')
        }
      }else if(this.$route.path == '/revenue-and-expenditure'){
        formData ={
          "country" : country,
          "group" : ['Government']
        }
      }else if(this.$route.path == '/labour-and-health'){
        formData ={
          "country" : country,
          "group" : this.store.selectedIndicator.split('&')
        }
      }else if(this.$route.path == '/forex-and-trade'){
        formData ={
          "country" : country,
          "group" : ['Money','Trade']
        }
      }else if(this.$route.path == '/latest-news'){
        formData ={
          page: 1,
          perPage: 12,
          country : [this.store.selectedCountry]
        }
      }
      if(this.$route.path == '/latest-news'){
         this.store.callNewsList(formData,true).then(() =>{
          }).catch( err => console.log(err.message))
      }else{
        this.store
          .getCountryEconomyData(formData, true)
          .then((response) => {
            
          })
          .catch((err) => console.log(err.message));
      }
    },
  },
  mounted(){
     am5.ready(() => {
        this.drawChart(this.store.selectedCountry);
    });
  },
  created(){
  }
}
</script>