<template>
     <h2 class="mb-3">Economic Overview</h2>
        <div class="form-group inputIcon tagInput position-relative mb-3">
            <div class="bootstrap-tagsinput" v-if="$route.path != '/economic-overview'">
                <span class="badge badge-info d-flex align-items-center">{{store.selectedCountry}} <vue-feather class="ms-1" size="12px" type="x"></vue-feather></span>
                <input type="text" class="form-control-tag" value="" /> 
                <a class="searchButton" href="javascript:void(0)"><vue-feather type="search"></vue-feather></a>
            </div>
            <select class="form-select" name="continental" v-model="$parent.continent" @change="$parent.callCountryEconomyData()" v-else>
                <option v-for="value,key in static_vars.economicOverviewContinents" :key="value">{{value}}</option>
                
            </select>
        </div>
</template>
<script>
import { useMainStore } from "@/store";
export default {
    setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {

    }
  },
}
</script>