<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <div class="innerPage-banner heightCalc py-4 whiteBG"
            v-if="!store.loading && Object.keys(store.EconomyOverview).length > 0">
            <div class="container-fluid h-100 calHeight">
                <div class="row h-100">
                    <div class="col-12 col-xl-3">
                        <!-- SearchBar -->
                        <economic-search></economic-search>
                        <div class="mainOverview p-3" v-for="value, key in store.EconomyOverview">
                            <h3 class="mb-1 text-center">India</h3>
                            <h4 class="green mb-4 text-center">{{ key }}</h4>
                            <div class="viewGDP position-absolute text-end">
                                <a data-bs-toggle="modal" data-bs-target="#exampleModal"
                                    class="d-inline-flex overview-small-btn" href="javascript:void(0)"><img
                                        src="assets/images/lineGraph.svg" alt="Icon" title="Chart" /></a>
                                <p class="text-end mb-0 lighttext">{{ value[0].Unit }}</p>
                            </div>
                            <perfect-scrollbar class="scroll-area scrollOverview">
                                <ul class="listCountry mb-0">
                                    <li v-for="list, index in value">
                                        <a href="javascript:void(0)"
                                            class="d-flex justify-content-between align-items-center"
                                            @click="showCountryData(list.Country)">
                                            <span class="d-flex align-items-center"><img class="me-2"
                                                    :src="'assets/images/country_flag/' + list.Country.toLowerCase() + '.webp'"
                                                    alt="Flag" :title="list.Country" />{{ list.Country }}</span>{{
                                                            list.LatestValue
                                                    }}
                                        </a>
                                    </li>
                                </ul>
                            </perfect-scrollbar>
                        </div>
                    </div>
                    <div class="col-12 col-xl-9">
                        <div class="mainRightMap">
                            <economic-menu></economic-menu>
                            <economic-map></economic-map>
                        </div>
                    </div>
                    <!-- 
                    <div class="col-12">
                        <bottom-navbar></bottom-navbar>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade economy-overview-country" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <div class="filterHeader d-flex align-items-center drop-1199">
                        <h2 class="mb-0">GDP</h2>
                        <div class="position-relative">
                            <a @click.prevent="dropdownMenu2 = !dropdownMenu2" href="javascript:void(0)"
                                class="pt-0 border-btn d-inline-flex align-items-center justify-content-between d-xl-none ms-2">
                                World
                                <i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i>
                            </a>
                            <ul class="dropdown_menu_animated onMbile pad-0 selectTab px-2 d-md-flex align-items-center mb-0"
                                :class="dropdownMenu2 ? 'show' : ''">
                                <li><a href="javascript:void(0)" class="active">World</a></li>
                                <li><a href="javascript:void(0)" class="">G20</a></li>
                                <li><a href="javascript:void(0)" class="">Europe</a></li>
                                <li><a href="javascript:void(0)" class="">America</a></li>
                                <li><a href="javascript:void(0)" class="">Asia</a></li>
                                <li><a href="javascript:void(0)" class="">Australia</a></li>
                                <li><a href="javascript:void(0)" class="">Africa</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="rightTab">
                        <button type="button" class="btn-bar active"><vue-feather size="20" type="bar-chart-2"></vue-feather></button>
                        <button type="button" class="btn-bar rotate"><vue-feather size="20" type="bar-chart-2"></vue-feather></button>
                        <button type="button" class="btn-close border" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                </div>
                <div class="modal-body text-center py-4">
                    <img src="assets/images/chart/chartBpi.svg" alt="chart" title="Chart" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
import economicmenu from "./files/economic_menu";
import economicmap from "./files/economic_map";
// import bottomnavbar from "./files/bottom_navbar";
import economicsearch from "./files/economic_search";
import { useMainStore } from "@/store";

export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            country: [],
            groupIndicators: 'GDP',
            continent: 'World',
            dropdownMenu2: false,

        }
    },
    components: {
        bannerSlider: bannerslider,
        economicMenu: economicmenu,
        economicMap: economicmap,
        // bottomNavbar:bottomnavbar,
        economicSearch: economicsearch,

    },
    methods: {
        showCountryData(country) {
            this.store.$patch({selectedCountry : country})
            this.store.$patch({ selectedIndicator: 'GDP' })
            this.$router.push({ name: 'gdp', params: { 'country': country } })
        },
        callCountryEconomyData() {
            let formData = {
                "indicator": [this.groupIndicators],
            };
            if (this.continent != 'World') {
                formData['continent'] = this.continent
            }
            if (this.country.length > 0) {
                formData['country'] = this.country[0]
            }
            this.store
                .getCountryEconomyData(formData, true)
                .then((response) => {

                })
                .catch((err) => console.log(err.message));
        },

    },
    created() {
        this.callCountryEconomyData()
    }
}
</script>
