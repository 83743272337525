<template>
    <ul class="d-flex gdpRate align-items-center justify-content-end">
        <li :class="[{'active' : $parent.groupIndicators == 'GDP'}]"><a href="javascript:void(0)" @click="$parent.groupIndicators = 'GDP';$parent.callCountryEconomyData()">GDP</a></li>
        <li :class="[{'active' : $parent.groupIndicators == 'GDP Annual Growth Rate'}]"><a href="javascript:void(0)" @click="$parent.groupIndicators = 'GDP Annual Growth Rate';$parent.callCountryEconomyData()">Growth</a></li>
        <li :class="[{'active' : $parent.groupIndicators == 'Interest Rate'}]"><a href="javascript:void(0)" @click="$parent.groupIndicators = 'Interest Rate';$parent.callCountryEconomyData()">Rate</a></li>
        <li :class="[{'active' : $parent.groupIndicators == 'Inflation Rate'}]"><a href="javascript:void(0)" @click="$parent.groupIndicators = 'Inflation Rate';$parent.callCountryEconomyData()">Inflation</a></li>
        <li :class="[{'active' : $parent.groupIndicators == 'Stock Market'}]"><a href="javascript:void(0)" @click="$parent.groupIndicators = 'Stock Market';$parent.callCountryEconomyData()">Markets</a></li>
        <li :class="[{'active' : $parent.groupIndicators == 'Currency'}]"><a href="javascript:void(0)" @click="$parent.groupIndicators = 'Currency';$parent.callCountryEconomyData()">Currencies</a></li>
        <li :class="[{'active' : $parent.groupIndicators == 'Corporate Tax Rate'}]"><a href="javascript:void(0)" @click="$parent.groupIndicators = 'Corporate Tax Rate';$parent.callCountryEconomyData()">Tax</a></li>
        <li :class="[{'active' : $parent.groupIndicators == 'Coronavirus Cases'}]"><a class="pe-0" href="javascript:void(0)" @click="$parent.groupIndicators = 'Coronavirus Cases';$parent.callCountryEconomyData()">COVID-19</a></li>
    </ul>
</template>
<script>
export default {
  data() {
    return {

    }
  },
}
</script>